import { waitAuth } from "~/utils/middleware";

import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Sub.Type.ObjectStorage);

    if (__subscription().has(Sub.Type.ObjectStorage)) {
        log("❌ Has S3");
        return navigateTo("/");
    }

    log("✅ Has no S3");
});
